let searchAjax = null;
$('.live-searching-form input').on('keypress', function (e) {
    var str = $(this).val();
    let route = $("meta[name='search-route']").attr('content');
    if (searchAjax != null) {
        searchAjax.abort();
    }
    $(".live-searching-form .live-searching-result").text('');
    $(".live-searching-form .live-searching-result").css('padding', '0px')
    let loading = `<div class="text-center"><i class="fa fa-spinner fa-pulse  fa-fw"></i> </div>`
    $(".live-searching-form .live-searching-result").html(loading);
    $(".live-searching-form .live-searching-result").css('padding', '.1rem');
    searchAjax = $.ajax({
        url: route,
        method: "post",
        data: {
            q: str,
            _token: $("meta[name='csrf-token']").attr('content')
        },
        success: function (response) {
            let data = '';
            for (const responseElement of response.data.list) {
                data += `  <li class="list-group-item border-0 border-bottom-dashed border-bottom-1"><a href="${responseElement.link}">
${responseElement.title}
<br>
<sub class="text-muted">${responseElement.description}</sub></a>
</li>`;
            }
            let view = `
<ul class="list-group">
${data}
</ul>
`;


            $(".live-searching-form .live-searching-result").html(view);
            $(".live-searching-form .live-searching-result").css('padding', '.5rem');
        }, error: function (xhr, status, error) {
            var response = JSON.parse(xhr.responseText)
            $(".live-searching-form .live-searching-result").html(response.message);
        }
    })


})
