$('.menu-sub-dropdown a.dropdown-toggle').on('click', function () {
    $(this).addClass('show');
    $(this).attr('aria-expanded', true)
})
$(document).on('submit', "#subscribeForm", function (event) {
    event.preventDefault();
    let action = $(this).attr('action');
    let data = $(this).serialize();
    $.ajax({
        url: action,
        method: "post",
        data: data,
        success: function (response) {
            $("#subscribe_message").html(response.data.message)
        },error: function (xhr, status, error) {
            var response = JSON.parse(xhr.responseText)
            $("#subscribe_message").html("<b class='text-danger'><i class='text-danger fa fa-times-circle'></i>  "+ response.message+"</b>" );

        }
    })
})

$(function () {
    if ($(".datepicker").length !== 0)
        $(".datepicker").datepicker({
            dateFormat: "yy-mm-dd"
        });
});




